import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../asset/logo-srl-png.png";
import "../Style/navbar.css";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

function NavigationBar() {
  const navigate = useNavigate();

  const redirectHomeOnClick = () => {
    navigate("/");
  };

  const redirectChiSiamoOnClick = () => {
    navigate("/chiSiamo");
    window.scrollTo(0, 0);
  };
  const redirectinvestOnClick = () => {
    navigate("/investPage");
    window.scrollTo(0, 0);
  };

  const redirectFaqOnClick = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };
  const redirectMissionOnClick = () => {
    navigate("/laNostraMission");
    window.scrollTo(0, 0);
  };
  const redirectValoriOnClick = () => {
    navigate("/valori");
    window.scrollTo(0, 0);
  };
  const redirectContattiOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };
  const redirectWorkOnClick = () => {
    navigate("/lavoraConNoi");
    window.scrollTo(0, 0);
  };
  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  return (
    <Navbar expand="lg" className="background-navbar px-4" fixed="top">
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            onClick={redirectHomeOnClick}
            className="logo-navbar "
            src={logo}
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle className="toggle-white">
          <RxHamburgerMenu className="text-white" />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav className="me-auto my-2 my-lg-0 ">
            <Nav.Link className="linkNav" href="/">
              Home
            </Nav.Link>

            <Nav.Link onClick={redirectChiSiamoOnClick} className="linkNav">
              Chi siamo
            </Nav.Link>
            <Nav.Link onClick={redirectinvestOnClick} className="linkNav">
              Investi con noi
            </Nav.Link>
            <Nav.Link className="linkNav" onClick={redirectMissionOnClick}>
              La nostra mission
            </Nav.Link>
            <Nav.Link className="linkNav" onClick={redirectValoriOnClick}>
              I nostri valori
            </Nav.Link>
            <Nav.Link className="linkNav" onClick={redirectBlogOnClick}>
              Approfondimenti
            </Nav.Link>
            <Nav.Link onClick={redirectFaqOnClick} className="linkNav">
              FAQ
            </Nav.Link>

            <NavDropdown
              title="Contatti"
              id="basic-nav-dropdown"
              className="linkNav"
            >
              <NavDropdown.Item onClick={redirectContattiOnClick}>
                Contatti
              </NavDropdown.Item>
              <NavDropdown.Item onClick={redirectWorkOnClick}>
                Lavora con noi
              </NavDropdown.Item>
            </NavDropdown>

            {/* <NavDropdown
              className="customNavDropdown style-dropdown"
              title="I Nostri Servizi"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="#action4">
                Creazione Siti Web
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Creazione di E-Commerce
              </NavDropdown.Item>
              <NavDropdown.Item href="#action6">
                Creazione di App personalizzate
              </NavDropdown.Item>
              <NavDropdown.Item href="#action7">
                Digital Marketing
              </NavDropdown.Item>
              <NavDropdown.Item href="/graphicDesignerPage">
                Graphic Designer
              </NavDropdown.Item>
              <NavDropdown.Item href="#action9">
                Assistenza e Manutenzione
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
