import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article16Img from "../../asset/article16-img.png";

const Article16Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article16Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Accollo del mutuo: cosa sapere e quali vantaggi per parti?
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          Procedere con l'accollo del mutuo può risultare conveniente sia per
          l’acquirente che per il venditore, ecco cosa serve per farlo quando si
          sta valutando di comprare una casa ma il venditore non ha ancora
          finito di pagare il mutuo. Può sembrare un grosso handicap per
          l’acquisto, in realtà non lo è affatto. La soluzione più efficace è
          l’accollo del mutuo tra privati: disciplinato dall'art. 1273 del
          Codice Civile, l'accollo del mutuo permette a una persona (detta
          accollante) di prendersi la responsabilità di rimborsare il
          prestito alla banca (detta accollatario) al posto della persona che ha
          originariamente contratto il  mutuo  (detta accollato). Questo
          passaggio non è difficile: basta rispettare i requisiti.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Come funziona l’accollo del mutuo
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
            L’accollo del mutuo permette a un soggetto, chiamato accollante, di
            sostituire il contraente originario del mutuo, ovvero l’accollato, e
            prendersi l’obbligo di restituire il debito maturato nei confronti
            della banca. Questo processo è disciplinato dall’art. 1273 del
            Codice Civile. L’accollo del mutuo permette al primo contraente di
            essere liberato di ogni onere riguardante il suddetto prestito una
            volta che quest’ultimo viene passato a un nuovo soggetto. Di fatto,
            il mutuo continua ad esistere ma intestato a un'altra persona che
            sarà obbligata a pagare le rate residue fino alla scadenza pattuita
            nella fase originaria di stipula del mutuo.
          </p>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
            L’accollo del mutuo può avvenire secondo quattro scenari principali:
          </p>
          <div className="align-items-start mb-5">
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>
                Accollo del mutuo tra privati: viene  venduta una casa su cui
                grava un mutuo ancora in corso
              </li>
              <li>
                Accollo del mutuo del costruttore: un costruttore decide di
                trasferire le rate di mutuo restanti all’acquirente. Nel caso
                dell'accollo del mutuo edilizio, la persona che acquista una
                casa subentra all'impresa costruttrice nel pagamento delle rate
                del finanziamento che la banca ha concesso a quest'ultima per la
                costruzione
              </li>
              <li>
                Accollo del mutuo cointestato per separazione: se uno dei due
                coniugi in fase di separazione decide di liberarsi della propria
                quota coniugale. In questo caso, attraverso l'accollo uno dei
                coniugi/conviventi acquista la quota dell'abitazione spettante
                all'altro, pagandola mediante accollo del debito nei confronti
                della banca e, quindi, facendo fronte al pagamento rateale
                mensile dell'intero mutuo.
              </li>
              <li>
                Accollo del mutuo per successione ereditaria: se non esiste una
                polizza vita, gli eredi devono continuare a pagare le rate del
                prestito ancora rimanenti. Bisogna allora trasferire la
                titolarità del finanziamento attraverso il cosiddetto “accollo
                del mutuo per successione”: questo significa che un'altra
                persona si fa carico del debito e diventa responsabile del
                rimborso del prestito.
              </li>
            </ul>
          </div>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Quanto conviene l’accollo del mutuo?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            L’accollo del mutuo può presentare vantaggi sia per l’accollante che
            per l’accollato. Chi cede il mutuo gode della liberazione
            dell’impegno di pagare le rate del mutuo originario senza avere
            l’obbligo di estinguere anticipatamente il mutuo, risparmiando così
            il pagamento della possibile penale. <hr /> Accollarsi un mutuo
            permette di ottenere un nuovo credito a costi inferiori perché non è
            necessario iscrivere una nuova ipoteca. Anche se le condizioni
            economiche dei due soggetti coinvolte nell’accollo del mutuo
            potrebbero non essere le stesse, l’accollante può contrattare con la
            banca per trovare una formula dalle condizioni più favorevoli.{" "}
            <hr /> Sarà comunque decisione dell’acquirente valutare se
            l’accollarsi il mutuo risulti conveniente dopo aver valutato
            attentamente tutte le condizioni del contratto mutuo originario,
            come durata o tasso di interesse. Bisognerà inoltre verificare che
            l’accollato sia in regola con i pagamenti per non rischiare di
            cadere in una truffa in piena regola.
          </p>
        </Container>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Come si fa ad accollarsi un mutuo?
        </h2>
        <div className="align-items-start mb-2">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Esistono poi diverse tipologie di accollo del mutuo:
          </p>
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              <span className="bold-900 me-1">Accollo cumulativo:</span> sia
              accollato che accollante rimangono in obbligo con l’istituto di
              credito. Se il nuovo debitore non fosse in grado di rimborsare il
              debito, la banca potrebbe tornare dal primo debitore
            </li>

            <li>
              <span className="bold-900 me-1">Accollo liberatorio:</span>{" "}
              l’accollato verrà liberato da ogni obbligo. Finita la transazione
              di accollo mutuo, l’accollato non avrò più nessun legame con il
              mutuo.
            </li>
          </ul>
        </div>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
          Scegliendo una di queste due modalità, accollato e accollante dovranno
          stipulare il passaggio tramite una serie di documenti che dovranno
          essere necessariamente convalidati da un notaio.
        </p>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Si può fare l’accollo del mutuo senza consenso della banca?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Se la banca non è d'accordo preventivamente, non si può effettuare
          l'accollo del mutuo liberatorio. In modo più semplice, la banca che ha
          concesso il prestito deve acconsentire al cambio di responsabile e
          rilasciare il debitore originario.
        </p>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          I documenti necessari per l’accollo del mutuo
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Tra i documenti necessari per procedere con l’accollo del mutuo
          troviamo la liberatoria, un documento sottoscritto da accollato,
          accollante e funzionario della banca, che permette all’accollato di
          svincolarsi in modo definitivo dal mutuo. È fondamentale perché
          quest’ultimo non rimanga vincolato al mutuo, nonostante non abbia la
          responsabilità di estinguere le rate, fino al momento del saldo
          completo.
        </p>

        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-4">
          In questo processo risulta una soluzione ideale la stipula di un atto
          notarile firmato dalle tre parti (notaio, accollato e accollante) per
          conferire una garanzia massima alla transazione.
        </p>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article16Text;
