import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./Pages/Homepage";
import ErrorPage from "./Pages/ErrorPage";
import ChiSiamoPage from "./Pages/ChiSiamoPage";
import InvestPage from "./Pages/InvestPage";
import Faq from "./Pages/Faq";
import LaNostraMission from "./Pages/LaNostraMission";
import Valori from "./Pages/Valori";
import Contact from "./Pages/Contact";
import LavoraConNoi from "./Pages/LavoraConNoi";
import InformationNoResp from "./Pages/InformationNoResp";
import Blog from "./Pages/Blog";
import Article1Text from "./Pages/Articoli/Article1Text";
import Article2Text from "./Pages/Articoli/Article2Text";
import Article3Text from "./Pages/Articoli/Article3Text";
import Article4Text from "./Pages/Articoli/Article4Text";
import Article5Text from "./Pages/Articoli/Article5Text";
import Article6Text from "./Pages/Articoli/Article6Text";
import Article7Text from "./Pages/Articoli/Article7Text";
import Article8Text from "./Pages/Articoli/Article8Text";
import Article9Text from "./Pages/Articoli/Article9Text";
import Article10Text from "./Pages/Articoli/Article10Text";
import Article11Text from "./Pages/Articoli/Article11Text";
import Article12Text from "./Pages/Articoli/Article12Text";
import Article13Text from "./Pages/Articoli/Article13Text";
import Article14Text from "./Pages/Articoli/Article14Text";
import Article15Text from "./Pages/Articoli/Article15Text";
import Article16Text from "./Pages/Articoli/Article16Text";
import Article17Text from "./Pages/Articoli/Article17Text";
import Article18Text from "./Pages/Articoli/Article18Text";
import Article19Text from "./Pages/Articoli/Article19Text";
import Article20Text from "./Pages/Articoli/Article20Text";
import Article21Text from "./Pages/Articoli/Article21Text";
import Article22Text from "./Pages/Articoli/Article22Text";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/chiSiamo" element={<ChiSiamoPage />} />
          <Route path="/investPage" element={<InvestPage />} />
          <Route path="/laNostraMission" element={<LaNostraMission />} />
          <Route path="/valori" element={<Valori />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contatti" element={<Contact />} />
          <Route path="/lavoraConNoi" element={<LavoraConNoi />} />
          <Route path="/information" element={<InformationNoResp />} />
          <Route path="/approfondimenti" element={<Blog />} />
          <Route path="/article1" element={<Article1Text />} />
          <Route path="/article2" element={<Article2Text />} />
          <Route path="/article3" element={<Article3Text />} />
          <Route path="/article4" element={<Article4Text />} />
          <Route path="/article5" element={<Article5Text />} />
          <Route path="/article6" element={<Article6Text />} />
          <Route path="/article7" element={<Article7Text />} />
          <Route path="/article8" element={<Article8Text />} />
          <Route path="/article9" element={<Article9Text />} />
          <Route path="/article10" element={<Article10Text />} />
          <Route path="/article11" element={<Article11Text />} />
          <Route path="/article12" element={<Article12Text />} />
          <Route path="/article13" element={<Article13Text />} />
          <Route path="/article14" element={<Article14Text />} />
          <Route path="/article15" element={<Article15Text />} />
          <Route path="/article16" element={<Article16Text />} />
          <Route path="/article17" element={<Article17Text />} />
          <Route path="/article18" element={<Article18Text />} />
          <Route path="/article19" element={<Article19Text />} />
          <Route path="/article20" element={<Article20Text />} />
          <Route path="/article21" element={<Article21Text />} />
          <Route path="/article22" element={<Article22Text />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
