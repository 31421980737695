import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article18Img from "../../asset/article18-img.png";

const Article18Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article18Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Quanto costa donare un’immobile a un parente?
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          Ecco quali sono i costi per la donazione di un immobile e le procedure
          per farlo nel modo più corretto. Donare un immobile può non essere
          semplice, vediamo infatti che i costi possono variare a secondo del
          grado di parentela con il beneficiario. Le aliquote previste in caso
          di donazione sono: 4% per il coniuge e i parenti in linea retta, da
          calcolare sul valore eccedente 1 milione di euro, per ciascun
          beneficiario, 6% per fratelli e sorelle, da calcolare sul valore
          eccedente 100.000,00 euro, per ciascun beneficiario. Ma scomponiamo
          questo processo più nel dettaglio, scoprendo quali sono realmente
          i costi per la donazione di un immobile e tutte le informazioni utili
          a riguardo.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Cosa si intende con donazione di un immobile
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
            Con donazione si intende un atto di liberalità compiuto nei
            confronti di un’altra persona. Nella maggior parte dei casi la
            donazione avviene come un passaggio tra genitori e figli. La
            donazione di un immobile può avvenire in due modalità:
          </p>

          <div className="align-items-start mb-5">
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>Diretta: quando si dona una casa che è già di proprietà</li>
              <li>
                Indiretta: quando si acquista un immobile e lo si intesta a
                qualcun altro
              </li>
            </ul>
          </div>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            È da ricordare che la donazione non può mai essere fatta su un
            possedimento futuro, bisogna sempre essere in presenza di una
            proprietà.
          </p>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Capire  come funziona la donazione di un immobile  è fondamentale,
            tra i tanti procedimenti deve essere trascritta nei pubblici
            registri immobiliari e volturata in catasto. La donazione andrà
            fatta alla presenza di un notaio che verificherà non solo la volontà
            del donante a donare l’immobile ma anche la volontà del
            beneficiario a riceverlo. In assenza della volontà del beneficiario,
            il notaio non potrà procedere con la stipula dell’atto di donazione.
          </p>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Quanto si spende per fare una donazione di un immobile?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Quando affrontiamo una donazione immobiliare è sempre necessario
            tenere a mente che andremo incontro a dei costi che si ripartiscono
            in spese notarili e imposte. Fondamentale sapere a cosa si
            riferiscono per non andare incontro a  problemi durante la donazione
            di un immobile .
          </p>
        </Container>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Costi del notaio per la donazione
        </h2>
        <div className="align-items-start mb-2">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Per quanto riguarda le spese notarili va tenuto conto che la loro
            entità è variabile in base al valore dell’immobile che si intende
            donare, ma anche secondo altri precisi criteri come:
          </p>
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>Ubicazione dell’immobile</li>

            <li>Ipoteche</li>
            <li>Vincoli di qualsiasi</li>
            <li>
              Tariffe del notaio (che variano da professionista a
              professionista).
            </li>
          </ul>
        </div>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Costi delle imposte per la donazione
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Trattando il tema delle imposte esistono dei costi fissi e dei costi
          variabili, sempre in relazione al valore dell’immobile. La base
          imponibile corrisponde al valore catastale dell’immobile e va
          calcolato rivalutando la rendita catastale del 5% e moltiplicandola
          per un coefficiente che varia a seconda della categoria dell’immobile.
          Gli altri costi da tenere in considerazione riguardano:
        </p>
        <div className="align-items-start mb-2">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Per quanto riguarda le spese notarili va tenuto conto che la loro
            entità è variabile in base al valore dell’immobile che si intende
            donare, ma anche secondo altri precisi criteri come:
          </p>
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>Imposta di Registro 200,00 euro</li>

            <li>Imposta di bollo 230,00 euro</li>
            <li>Vincoli di qualsiasi</li>
            <li>
              Imposta Ipotecaria 200,00 euro se prima casa o 2% del valore
              catastale se seconda casa
            </li>
            <li>
              Imposta Catastale 200,00 euro se prima casa o 1% del valore
              catastale se seconda casa
            </li>
            <li>Imposta di Donazione (variabile).</li>
          </ul>
        </div>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
          Va reso noto che l’Imposta di Donazione può subire variazioni quando
          la donazione è effettuata a beneficio di un parente.
        </p>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Quali sono le imposte per la donazione a un figlio o un parente?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Nel caso in cui il vostro beneficiario sia vostro figlio, l’Imposta di
          donazione non è dovuta fino a un valore di 1 milione di euro
          dell’immobile che si sta procedendo a donare. Se invece il valore
          dell’immobile è superiore a 1 milione di euro sarà necessario pagare
          l’Imposta di Donazione nella misura del 4% della cifra eccedente.
          Questo discorso si applica anche nel caso in cui il beneficiario sia
          un coniuge, genitore, nonno o nipote del donante.
        </p>

        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-4">
          Invece, quanto costa una donazione tra fratelli? La situazione è
          leggermente diversa se si tratta di un fratello o una sorella del
          donante: la soglia di valore immobiliare è 100 mila euro, dopo diche
          l’Imposta di Donazione sarà dovuta con un valore del 6% sul valore
          eccedente. Per tutti gli altri parenti e affini è prevista un’Imposta
          di Donazione al 6% su tutto il valore dell’immobile, senza alcuna
          franchigia.
        </p>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Si può donare un bene senza pagare tasse e notaio?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Purtroppo, non è possibile donare un bene senza pagare tasse e notaio.
          Questo perché la maggior parte dei costi prescinde il valore del bene
          e fa riferimento a una serie di imposte fisse che sono legate proprio
          a questo passaggio di proprietà di beni, immobili o meno.
        </p>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article18Text;
