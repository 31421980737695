import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article19Img from "../../asset/article19-img.png";

const Article19Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article19Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Quanto tempo si impiega per vendere una casa? <br />
          Le variabili da considerare
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          Vendere una casa impiega del tempo, quali sono le variabili da
          considerare? Il proprietario dell’immobile desidera concludere la
          vendita nel minor tempo possibile, nella realtà però, subentrano
          diverse variabili difficilmente prevedibili. I fattori che influiscono
          sulle tempistiche di vendita sono differenti: il mercato, il servizio
          e il prezzo sono gli elementi principali che determinano in quanto
          tempo è possibile vendere una casa.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Le tempistiche per vendere un immobile: i fattori che influenzano
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
            I fattori che influenzano le tempistiche di vendita di un immobile
            sono:
          </p>

          <div className="align-items-start mb-5">
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>La posizione dell’immobile;</li>
              <li>Il tipo di immobile;</li>
              <li>La condizione della casa;</li>
              <li>Il prezzo di vendita;</li>
              <li>Il mercato immobiliare;</li>
              <li>La stagionalità;</li>
              <li>La fortuna.</li>
            </ul>
          </div>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Posizione dell’immobile
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            La posizione dell’abitazione è un elemento rilevante, influenzando
            la tempistica di vendita. Ogni luogo ha le sue peculiarità, e
            possiamo contraddistinguere alcune macro-categorie, tra cui:
          </p>
          <div className="align-items-start mb-5">
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>
                Grandi città: solitamente una casa situata in grandi metropoli
                come Milano, Roma, e Firenze, è vendibile senza troppe
                difficoltà. Inoltre, anche le zone limitrofe e ben collegate con
                i trasporti sono terreno fertile di vendita;
              </li>
              <li>
                Luoghi turistici: luoghi di mare o di montagna sono allettanti
                per i compratori, nonostante le tasse sulle seconde case e il
                desiderio da parte delle nuove generazioni di trascorrere le
                vacanze sempre in luoghi differenti, non volendo avere un posto
                fisso;
              </li>
              <li>
                Poli industriali, universitari, ospedalieri; gli immobili in
                queste zone sono molto richiesti, compreso chi, ha in progetto
                di acquistare l’abitazione per poi affittarla a lavoratori e
                studenti;
              </li>
              <li>
                Zone periferiche: nelle aree che non presentano particolari
                attrazioni, vendere una casa potrebbe richiedere tempi più
                lunghi.
              </li>
            </ul>
          </div>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Nelle zone periferiche, si registra una costante diminuzione di
            popolazione, riflettendosi anche sui tempi necessari di vendita.
          </p>
        </Container>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Tipo di immobile e condizione della casa
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Nelle città, solitamente c’è grande richiesta da parte dei potenziali
          acquirenti, di case di piccole o medie dimensioni come monolocali o
          bilocali. La medesima situazione è presente per le zone turistiche e
          per i poli industriali, anche se possono esserci delle eccezioni, tra
          cui:
        </p>
        <div className="align-items-start mb-5">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              Cittadine adiacenti a grandi città: in queste aree, le persone
              cercando delle villette, perché sentono l’esigenza di scappare dal
              caos cittadino;
            </li>

            <li>
              Luoghi di montagna: in montagna è presente il mercato delle baite;
            </li>
            <li>
              Borghi e quartieri con abitazioni ad alto profilo: le case in
              questi casi hanno caratteristiche di pregio, come soffitti
              d’epoca.
            </li>
          </ul>
        </div>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          In caso in cui, la l’immobile non dovesse rispecchiare queste
          caratteristiche, e si ha una casa grande, la soluzione potrebbe essere
          dividere l’abitazione in mono o bilocali. Mentre, se si è in possesso
          di una cascina in campagna potrebbe essere utile trasformarla in un
          agriturismo o un B&B.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Inoltre, la  presentazione della casa  è molto importante per la sua
          vendita. Una casa ben presentata può essere venduta rapidamente,
          mentre una mal presentata può rimanere sul mercato per mesi. Con poche
          migliorie, come svuotare, rimuovere gli oggetti inutili, tinteggiare,
          inserire punti luce strategici e piccoli dettagli d'arredo, è
          possibile rendere la casa più attraente per i potenziali acquirenti.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          La tecnica dell'home staging può aiutare a trasformare una casa in un
          gioiello che attirerà molti acquirenti, soprattutto per chi cerca una
          casa già pronta da abitare e non vuole intervenire con lavori di
          ristrutturazione.
        </p>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Il prezzo di vendita
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Il prezzo è un elemento molto significativo nella tempistica di
          vendita. Per questo motivo, Nel caso in cui non si avesse fretta di
          vendere l’abitazione, si potrebbe richiedere un sovrapprezzo.
          Solitamente però, questa strategia non funziona, in quanto l’attesa
          spesso si riflette in una perdita economica, di conseguenza è
          consigliabile venderla il prima possibile.
        </p>
        <div className="align-items-start mb-2">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Le fasce di prezzo sono 2:
          </p>
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>Fascia di prezzo che rispecchia il reale valore di mercato;</li>

            <li>
              Fascia di prezzo a cui è disposto pagare solo chi si innamora
              dell’abitazione.
            </li>
          </ul>
        </div>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
          Vendere Ad un valore di mercato non richiede troppo tempo,
          specialmente se ci si trova in una grande città, viceversa per cedere
          un’abitazione al di sopra del valore di mercato, è possibile che la
          tempistica si raddoppi.
        </p>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Condizioni di mercato e stagionalità
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Il mercato immobiliare è ciclico, ciò significa che ci sono periodi in
          cui il mercato è caldo, viceversa ci sono momenti in cui è più
          difficile vendere. Lo stato di salute del mercato immobiliare è
          visibile anche dal tempo di vendita di un’abitazione; infatti, un
          mercato caldo ha una domanda alta, offerta bassa e prezzi in crescita,
          sicuramente in questo caso il venditore è avvantaggiato. Alcuni
          mercati immobiliari risentono dell’impatto stagionale, ma in generale
          le condizioni possono cambiare molto rapidamente.
        </p>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">Fortuna</h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          La fortuna è un ulteriore elemento da prendere in considerazione
          quando si vende un immobile, può essere che la casa abbia un prezzo
          sopra la media ma qualcuno se ne innamori e sia disposto a pagarla con
          un sovrapprezzo, viceversa potrebbe essere la casa perfetta ma non
          essere notata e non ricevere il giusto riconoscimento.
        </p>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Quanto tempo ci vuole per vendere una casa?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Prevedere quanto tempo richiede la vendita di una casa non è così
          semplice, per vendere un immobile bisogna:
        </p>
        <div className="align-items-start mb-2">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              Avere parametri positivi e adottare una strategia vincente, in
              questi casi potrebbe richiedere qualche settimana fino a due mesi;
            </li>

            <li>Se la situazione è nella media passano dai tre ai sei mesi;</li>
            <li>
              Nel caso in cui i parametri non fossero favorevoli, si potrebbe
              impiegare da sei mesi ad un anno. Il suggerimento è quello di
              rivedere la strategia di vendita, il prezzo o la comunicazione;
            </li>
            <li>
              Ottimizzare il processo di vendita è essenziale nei casi in cui i
              tempi superino l’anno;
            </li>
          </ul>
        </div>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
          Infine, se la casa è in vendita da oltre un anno un anno e mezzo e non
          c’è ancora stato nessun potenziale acquirente disposto a comprarla,
          probabilmente il prezzo è fuori mercato o la strategia di vendita è
          sbagliata, ma nulla che non si possa risolvere sistemando i parametri.
        </p>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article19Text;
