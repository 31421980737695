import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import ScrollButtonInvestForm from "../Components/ScrollButtonInvestForm";
import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";

import backInvest from "../asset/Invest-bg-80opacity.png";
import aumentoPrice from "../asset/aumentoPrezzi.png";
import Investitor from "../asset/investitor.png";
import InvestimentSicure from "../asset/investiment-sicure.png";
import ImgComplesso from "../asset/Complesso.png";
import ImgSingolAbitation from "../asset/SingolaAbitazione.png";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import bgTecnologic from "../asset/bg-tecnologico.png";
import ContactFormSmall from "../Components/ContactFormSmall";
import ContactForm from "../Components/ContactForm";

const InvestPage = () => {
  const InvestStyle = {
    backgroundImage: `url(${backInvest})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  const personalAppStyle = {
    backgroundImage: `url(${bgTecnologic})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",

    backgroundPosition: "center",
    minHeight: "70vh", // Imposta l'altezza minima al 100% della viewport
    zIndex: -1,
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid style={InvestStyle} className="img-zoom overflowHidden">
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center color-title text-center fade-in h-100 "
        >
          <h1 className="mt-5 fw-bold red-logo  ">INVESTI CON NOI </h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav overflowHidden p-lg-5">
        <h1 className=" text-center text-white fw-bold ">
          Investi in sicurezza nei nostri progetti nel Real Estate. <hr />
          Investimenti mirati a creare valore.
        </h1>
        <p className=" p-4 fs-5 text-white  text-center">
          <span className=" fw-bold fs-3 d-block">
            Scegli il mercato Immobiliare per il suo rapporto esclusivo rischio
            rendimento.
          </span>{" "}
          Se vuoi trasformare un investimento improvvisato in un investimento
          conveniente, profittevole e professionale; devi arginare i rischi e
          agire con metodo e strategia per massimizzare la redditività.
        </p>
      </Container>
      <Container fluid className="overflowHidden bgBluOpacity05  shadow py-4">
        <div className="d-flex align-items-center flex-column justify-content-center my-5  colortext-blunav">
          <p className="text-center fs-2 fw-bold ">
            Gli investimenti immobiliari sono riconosciuti come i più sicuri,
            <br /> ti illustriamo il perché{" "}
          </p>
        </div>
        <Container>
          <Row className=" d-flex align-items-center justify-content-start ">
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h4 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  Stabilità e aumento continuo dei prezzi degli immobili
                </h4>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Quando investiamo in qualcosa, ci aspettiamo di recuperare il
                  nostro investimento con un profitto. Ogni investimento
                  comporta una percentuale di rischio, di cui ogni investitore
                  deve essere consapevole. A differenza dell’investimento in
                  strumenti finanziari come azioni, obbligazioni o fondi di
                  investimento, gli investimenti immobiliari sono considerati
                  una forma d’investimento sicura, dimostrata e certificata da
                  decenni di risultati avuti da innumerevoli imprenditori e
                  investitori immobiliari.
                </p>
              </div>

              <div className=" d-flex justify-content-center align-items-center ">
                <ScrollButtonInvestForm />
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <img
                src={aumentoPrice}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <img
                src={InvestimentSicure}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h4 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  Stabilità e redditività degli investimenti
                </h4>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Un investimento si definisce stabile quando il sottostante non
                  soffre di volatilità o cambio del prezzo. Un investimento si
                  definisce redditivo quando lo storico del sottostante, che non
                  significa certezza del futuro, garantisce e dimostra un
                  aumento del prezzo costante negli anni. Il valore delle
                  attività finanziarie può diminuire in una sola notte a causa
                  di qualsiasi evento economico e politico. Nel caso
                  dell’immobiliare, la continua crescita e la bassa volatilità
                  dei prezzi definiscono l’investimento sull’immobile come uno
                  dei mercati più stabili e redditizi, dimostrato in questi
                  decenni.
                </p>
              </div>

              <div className=" d-flex justify-content-center align-items-center ">
                <ScrollButtonInvestForm />
              </div>
            </Col>

            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h4 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  I nostri investitori
                </h4>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Il nostro focus è duplice, ci rivolgiamo a nuovi investitori
                  desiderosi di iniziare a massimizzare il rendimento del loro
                  capitale, e ad investitori esperti che desiderano
                  diversificare i propri portafogli.
                </p>
              </div>

              <div className=" d-flex justify-content-center align-items-center ">
                <ScrollButtonInvestForm />
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <img
                src={Investitor}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={personalAppStyle}>
        <Row className="d-flex ">
          <Col
            data-aos="zoom-in-left"
            data-aos-offset="200"
            data-aos-duration="2000"
            className=" hover-img  rounded flex-column d-flex justify-content-center  align-items-center  py-2"
          >
            <h2 className="text-center fw-bold pt-5 mb-5 fs-1 text-white">
              Puoi monitorare il tuo investimento
            </h2>
            <p className=" text-center fw-bold  rounded mb-3  fs-4 width-100Smarphone width-70Pc   text-white line-height3 ">
              Tutti i nostri investitori potranno monitorare l'andamento
              dell’operazione alla quale partecipano tramite una Web App
              dedicata, all’interno della quale troveranno il Business Plan
              dettagliato, lo stato avanzamento lavori, fotografie, ed un report
              periodico così da restare costantemente aggiornati. <hr />{" "}
              Inoltre, su richiesta, sarà possibile visionare personalmente
              l’immobile e lo stato avanzamento dei lavori, cosi da toccare con
              mano il proprio investimento.
            </p>
            <Button
              className="d-flex align-items-center justify-content-center   mt-5 shadow p-2 button-redLogo mb-5"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              data-aos-offset="200"
            >
              {" "}
              <span className="fs-1 ">Coming Soon..</span>{" "}
            </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid className="overflowHidden py-5 shadow">
        <Container
          className="overflowHidden py-5 "
          data-aos="fade-left"
          data-aos-offset="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          <h2 className="text-center fw-bold pt-5 mb-5 fs-1 red-logo">
            Redditività
          </h2>
          <p className="text-center fw-bold  mb-5 fs-3 colortext-blunav ">
            Garantiamo un ritorno sull’investimento più alto rispetto alla media
            degli investimenti disponibili sul mercato, il nostro rendimento
            medio annuo è del 12,81%. La durata media dei nostri investimenti è
            di 10 mesi.
          </p>
        </Container>
      </Container>
      <Container className="overflowHidden py-5 ">
        <h3
          className="text-center fw-bold pt-5 mb-5 fs-1 colortext-blunav "
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          A breve partiranno nuovi progetti immobiliari
        </h3>
        <h3
          className="text-center fw-bold pt-5 mb-5 fs-1 colortext-blunav "
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          Ti invitiamo a iscriverti alla nostra lista Investitori Premium
        </h3>
        <p className="text-center fw-bold  mb-5 fs-3 colortext-blunav ">
          Potrai essere avvisato in anteprima sulle nostre nuove opportunità,
          iscrivendoti alla nostra lista Investitori Premium potrai essere
          contattato in maniera automatica appena un nuovo progetto
          d’investimento sarà disponibile.
        </p>
      </Container>
      <ContactFormSmall />

      <CallToActionNumberGreen />

      <Footer />
    </>
  );
};
export default InvestPage;

{
}
