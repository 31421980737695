import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import missionImg from "../asset/missionImg.jpg";
import "../Style/mission.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Mission = () => {
  const navigate = useNavigate();

  const redirectinvestOnClick = () => {
    navigate("/investPage");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container className="my-5">
        <h2 className="text-center fw-bold red-logo mb-5 fs-1">
          LA NOSTRA MISSION
        </h2>
        <Row>
          <Col
            className="text-white d-flex flex-column justify-content-center align-items-center "
            lg={6}
            md={12}
            sm={12}
            xs={12}
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="200"
          >
            <p className="mt-3 line-height3 fs-5 text-center colortext-blunav fw-bold p-2">
              La Mission della società 11. 22 Sviluppo Immobiliare Srl è quella
              di rendere accessibile il settore degli investimenti immobiliari a
              tutti coloro che non dispongono di grandi capitali <hr /> così da
              poter offrire l’opportunità di entrare in questo settore ed
              ottenere delle rendite profittevoli, in maniera completamente
              passiva.
            </p>
            <Button
              onClick={redirectinvestOnClick}
              className="button-presentation fs-3 my-3"
            >
              Investi con noi
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-offset="200"
          >
            {" "}
            <img
              src={missionImg}
              alt="img presentazione"
              className="rounded w-100 mb-4 shadow "
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Mission;
