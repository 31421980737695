import React from "react";
import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Style/barraLaterale.css";
import { MdOutlineEmail } from "react-icons/md";

const BarraLaterale = () => {
  const phoneNumber = "800160722";

  const emailAddress = " info@1122sviluppoimmobiliare.com";

  const handleClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <div className="socialIconsBar socialIconsBarMobile rounded-start z-index-200 ">
      <Link
        to={
          "https://www.facebook.com/profile.php?id=100092451962248&locale=it_IT"
        }
      >
        <FaFacebook className="icon" />
      </Link>
      <Link to={"https://www.instagram.com/11.22_sviluppo_immobiliare_srl/"}>
        <FaInstagram className="icon" />
      </Link>

      <Link on onClick={handleClick}>
        <MdOutlineEmail className="icon" />
      </Link>
      <a href={`tel:${phoneNumber}`} style={{ textDecoration: "none" }}>
        <FaPhone className="icon" />
      </a>
    </div>
  );
};

export default BarraLaterale;
