import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";
import Lottie from "lottie-react";
import animationArrow from "../asset/Lottie-animation/Animation - arrowDown.json";
import ContactBGIntro from "../asset/contattiBG-80op.png";
import numberGreen2 from "../asset/numeroVerde1122SviluppoImmobiliare.png";

import ContactFormSmall from "../Components/ContactFormSmall";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";

const Contact = () => {
  const navigate = useNavigate();

  const redirectJobOnClick = () => {
    navigate("/lavoraConNoi");
    window.scrollTo(0, 0);
  };

  const scrollToContactArrow = () => {
    const contactForm = document.getElementById("SectionForm");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  const ContactIntroStyle = {
    backgroundImage: `url(${ContactBGIntro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  const emailAddress = " info@1122sviluppoimmobiliare.com";

  const handleClickEmail = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container
        fluid
        style={ContactIntroStyle}
        className="img-zoom overflowHidden "
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center color-title text-center fade-in  "
        >
          <h1 className="mt-5 fw-bold title-color-red  ">CONTATTI</h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <p className="  fs-2 text-white  text-center">
          Puoi contattarci per maggiori informazioni tramite mail o numero verde
          gratuito
          <hr />
          <span className="fs-2">
            oppure compila il Form per essere ricontattato{" "}
          </span>
        </p>
        <div
          className="d-flex  justify-content-center
         align-items-center"
        >
          <Lottie
            onClick={scrollToContactArrow}
            data-aos="fade-up pointer"
            className=" w-iconLottie"
            animationData={animationArrow}
            loop={true}
          />
        </div>
      </Container>

      <Container fluid className="overflowHidden shadow">
        <Row>
          <Col
            className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5  rounded  "
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <span
              className="text-center fw-bold  fs-3 mb-4 red-logo red-logoForm"
              data-aos="fade-up-right"
              data-aos-duration="2000"
            >
              Scrivici al nostro indirizzo email
            </span>
            <div className="my-3 " data-aos="fade-up" data-aos-duration="2000">
              <Link onClick={handleClickEmail}>
                <Button className="button-form  fs-5">
                  {" "}
                  Info@1122sviluppoimmobiliare.com
                </Button>
              </Link>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex justify-content-center align-items-center"
            data-aos="fade-up-left"
            data-aos-offset="200"
            data-aos-duration="2000"
          >
            <img
              src={numberGreen2}
              className="w-50 p-4"
              alt="img general contractor"
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className=" d-flex justify-content-center align-items-center py-5  "
      ></Container>
      <ContactFormSmall />
      <Footer />
    </>
  );
};
export default Contact;
