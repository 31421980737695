import React from "react";
import Card from "react-bootstrap/Card";
import article4Img from "../../asset/article4-img.png";

import { Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article4() {
  const navigate = useNavigate();

  const redirectArticle4 = () => {
    navigate("/article4");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article4Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Investimenti immobiliari in Italia, vediamo come è andato l’anno
            2023.
          </Card.Title>

          <Button onClick={redirectArticle4} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article4;
