import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article15Img from "../../asset/article15-img.png";

const Article15Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article15Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Detrazione interessi passivi sul mutuo prima casa quando non residenti
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          La residenza è prerequisito fondamentale per accedere alla detrazione
          degli interessi sul mutuo, vi sono però tre eccezioni. <hr /> È
          possibile approfittare della detrazione sugli interessi passivi sul
          mutuo prima casa, anche quando non si è residenti presso
          quell’immobile? Può sembrare una domanda insolita, in realtà è una
          situazione che coinvolge numerosi cittadini: coloro che si devono
          trasferire altrove per lunghi periodi date ragioni lavorative, ad
          esempio, ma anche chi si trova momentaneamente impossibilitato a
          concludere il cambio di residenza. <hr /> In linea generale,
          la residenza presso l’abitazione adibita come prima casa è un
          prerequisito fondamentale, per poter approfittare delle detrazioni
          IRPEF sugli interessi del mutuo. Senza questa condizione, di
          conseguenza, si perdono tutti i benefici connessi. La legge ammette
          però alcune eccezioni, come il trasferimento urgente per motivi di
          lavoro, il ricovero in strutture di cura o l’inagibilità
          dell’immobile.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Come funziona la detrazione degli interessi passivi sul mutuo prima
            casa
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
            Innanzitutto, prima di analizzare le eccezioni sulla residenza
            previste dal nostro ordinamento, è utile capire come funzioni la
            detrazione degli interessi passivi sul mutuo prima casa.
          </p>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
            Regolata dall’ articolo 15 del Testo Unico delle Imposte sui Redditi
             (TUIR), e con nuovi limiti introdotti con la Legge Finanziaria del
            2008 (Legge 244/2007), la disciplina permette di fruire di
            una detrazione IRPEF del 19% sugli interessi pagati all’istituto di
            credito per il mutuo sulla prima casa, nonché sugli oneri accessori,
            per un massimo di 4.000,00 euro annui. Naturalmente, per
            massimizzare la detrazione, è una buona idea scegliere un mutuo che
            permetta di rimanere il più possibile entro questa soglia: ecco
            perché è utile  simulare la rata mensile  prima di sottoscrivere il
            finanziamento.
          </p>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <div className="mb-4">
            <p className="  colortext-blunav fs-4 line-height2 fw-bold text-center">
              Per poter beneficiare della detrazione in sede di dichiarazione
              dei redditi, però, è necessario rispettare alcune condizioni:
            </p>
          </div>
          <div className="align-items-start mb-5">
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>
                L’immobile deve essere adibito ad abitazione principale del
                contribuente titolare del mutuo. in presenza di cointestatari,
                come ad esempio i coniugi, ognuno potrà approfittare delle
                detrazioni IRPEF in base alla quota di possesso.
              </li>
              <li>
                Dalla data di acquisto dell’immobile, ovvero dal momento del
                rogito, i proprietari hanno 12 mesi di tempo per
                potervi trasferire la residenza, condizione necessaria affinché
                la casa venga considerata abitazione principale;
              </li>
            </ul>
          </div>
          <div className="align-items-start mb-2">
            <p className="  colortext-blunav fs-4 line-height2 fw-bold text-center">
              Oltre a questi requisiti, ve ne sono altri relativi alla stessa
              sottoscrizione del mutuo:
            </p>
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>
                Il mutuo deve essere stipulato entro 12 mesi, antecedenti o
                successivi, alla formalizzazione dell’acquisto;
              </li>
              <li>
                Il mutuo deve essere erogato a un soggetto residente in Italia o
                in uno Stato Membro dell’Unione Europea;
              </li>
              <li>
                Se il mutuo viene richiesto per la costruzione o
                la ristrutturazione dell’abitazione, dovrà essere sottoscritto
                entro 6 mesi antecedenti o 18 successivi all’inizio dei lavori,
                come previsto dalla Legge 222 del 2007.
              </li>
            </ul>
          </div>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Va inoltre specificato che l’accesso alle detrazioni è ammesso solo
            per i mutui ipotecari, ovvero coperti dalla garanzia dell’ipoteca.
            Per questa ragione, è utile valutare preventivamente le tipologie di
            finanziamento disponibili e  scegliere il mutuo più adatto  alle
            proprie esigenze, al netto delle agevolazioni.
          </p>
        </Container>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Quando non si possono detrarre gli interessi passivi sul mutuo?
        </h2>
        <div className="align-items-start mb-2">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Altrettanto utile è comprendere quando non si possano detrarre gli
            interessi passivi sul mutuo, sempre legato alla prima casa. Tra le
            casistiche principali, si elencano:
          </p>
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              Quando si sottoscrive una tipologia di finanziamento diversa dal
              mutuo o, ancora, quando il mutuo non è garantito da garanzia
              ipotecaria;
            </li>
            <li>
              Quando il titolare del mutuo non trasferisce la residenza presso
              l’immobile entro i 12 mesi dall’acquisto;
            </li>
            <li>
              Quando il titolare del mutuo fruisce di un regime fiscale
              agevolato che non prevede detrazioni IRPEF, ad esempio un
              professionista con Partita Iva in Regime Forfettario.
            </li>
          </ul>
        </div>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
          Appare perciò evidente che il trasferimento della residenza presso
          l’immobile sia un requisito indispensabile per poter accedere alle
          relative agevolazioni fiscali. Tanto che, come specificato dalla 
          Circolare 95/DF del 2000 , i benefici vengono meno anche quando il
          mancato cambio di residenza sia imputabile al Comune, ad esempio per
          un ritardo nel rilascio dei relativi documenti. Esistono, però, alcune
          eccezioni, riportate di seguito.
        </p>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Detrazioni degli interessi se non si è residenti: quando sono ammesse
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Come accennato nel precedente paragrafo, trasferire la residenza
          sull’immobile è fondamentale per poter usufruire delle relative
          detrazioni sugli interessi del mutuo. Tuttavia, sono previsti tre casi
          in cui è possibile inserire gli interessi passivi del mutuo in sede di
          dichiarazione dei redditi, e approfittare così delle agevolazioni
          IRPEF, anche qualora si avesse la residenza altrove:
        </p>
        <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
          <li>
            Quando il contribuente si deve trasferire per motivi di lavoro,
            sopraggiunti dopo il rogito dell’immobile. È ammesso anche il
            trasferimento all’estero, tuttavia la detrazione decade se si
            acquista in loco un nuovo immobile da adibire ad abitazione
            principale;
          </li>
          <li>
            Quando il contribuente necessita di un ricovero in un istituto di
            cura, purché non fornisca l’immobile in affitto a terzi;
          </li>
          <li>
            Quando, a seguito di calamità naturali come un terremoto, l’immobile
            viene dichiarato inagibile. In questo caso, per poter continuare a
            fruire delle detrazioni è necessario che le rate del mutuo vengano
            comunque corrisposte.
          </li>
        </ul>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          È inoltre utile specificare che al personale delle Forze Armate e
          delle Forze di Polizia in servizio permanente è riconosciuta la
          detrazione, anche se l’immobile non costituisce dimora abituale,
          purché si tratti dell’unica abitazione di proprietà.
        </p>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Cosa succede alle detrazioni se si cambia residenza?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Non capita di rado che il contribuente si trovi nella condizione di
          dover cambiare residenza, rispetto all’abitazione principale,
          successivamente all’acquisto dell’immobile e della stipula del
          relativo mutuo. Cosa accade alle detrazioni sugli interessi passivi,
          se non ci si trova nelle tre eccezioni previste per legge? <hr />
          Quando si cambia residenza, e quindi l’immobile non è più considerato
          l’abitazione principale, si perdono le relative agevolazioni IRPEF.
          Tuttavia, se in un secondo momento dal cambio di residenza la si
          volesse nuovamente trasferire sullo stesso immobile, rendendolo così
          nuovamente abitazione principale, si potrà nuovamente fruire delle
          detrazioni dalla data di rientro.
        </p>
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Come si accede alla detrazione degli interessi passivi sul mutuo
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-4">
          Infine, è utile ricordare come si possa detrarre gli interessi passivi
          del mutuo, sia per i residenti che per i non residenti, purché
          rientrino nelle eccezioni previste per legge. Come già spiegato, le
          detrazioni vengono inserite in fase di dichiarazione dei redditi,
          quindi sul 730 o sul Modello Unico - allegando l’opportuna
          documentazione:
        </p>
        <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
          <li>
            La copia del contratto di mutuo, con la specifica che l’immobile è
            adibito ad abitazione principale;
          </li>
          <li>La copia dell’atto di compravendita;</li>
          <li>L’autocertificazione di residenza;</li>
          <li>Le quietanze di pagamento degli interessi passivi sul mutuo.</li>
        </ul>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Le detrazioni verranno riconosciute in base a quanto versato per
          l’anno fiscale oggetto della dichiarazione reddituale, fino a un tetto
          massimo di 4.000,00 euro. <hr /> Poiché potrebbero essere necessari
          ulteriori approfondimenti, considerando come ogni situazione sia a sé
          stante - il caso tipico è, ad esempio, quello di due coniugi
          cointestatari che optano per separazione o divorzio dopo l’acquisto
          dell’immobile, il consiglio è quello di affidarsi a un
          valido commercialista, per vagliare l’effettiva possibilità di
          accedere alle detrazioni IRPEF previste.
        </p>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article15Text;
