import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article1Img from "../../asset/article1-img.png";

const Article1Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0  ">
        <img src={article1Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Mondo immobiliare, 4 trend da monitorare per l’anno 2024
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center">
          Il 2023 è stato un anno cruciale e sfidante per il mercato
          immobiliare, che si è trovato a dover affrontare numerose
          problematiche. Le previsioni 2024 sono però abbastanza diverse:
          secondo gli esperti di Bnp Paribas REIM, gli investitori avranno
          maggiori opportunità per implementare nuove strategie per il Real
          Estate. Questo perché potranno contare su uno scenario meno caotico
          per quanto riguarda prezzi e politica monetaria. Ecco allora quale
          2024 aspetta gli investimenti immobiliari e i cinque trend da tenere
          d’occhio nell’anno appena iniziato.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Gli Asset immobiliari in cui investire nel 2024
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Gli investitori in Real Estate, secondo Bnp Paribas REIM, dovrebbero
            puntare su quelle tipologie di Asset destinate a crescere nei
            prossimi 5-10 anni. Tra queste rientra l’intero segmento del living
            (appartamenti, ma anche case di riposo ed Healthcare), sostenuto dai
            cambiamenti demografici e dall’innalzamento dell’età media della
            popolazione; le strutture ad uso logistico, alimentate dalla
            crescita senza sosta dell’e-commerce; e le strutture per il tempo
            libero come gli Hotel, che possono sfruttare il recupero del settore
            a seguito della pandemia. Anche il segmento uffici continua ad
            attrarre gli investitori, grazie a un ampio spettro di strategie:
            gli investitori core si concentreranno maggiormente sul comparto
            CBD, con i rendimenti guidati maggiormente dalla scarsa offerta e
            dalla solida crescita degli affitti, mentre gli investitori value
            add avranno a disposizione opportunità interessanti sulle locazioni
            secondarie e sugli Asset non esigibili, attraverso la creazione di
            valore tramite un’oculata attività di Asset Management.“Quest’anno,
            dovremmo assistere a una graduale ripresa, commenta Laurent
            Ternisien, Deputy Head di BNP Paribas REIM, grazie a diversi fattori
            come la riduzione dell’inflazione, mercati del lavoro robusti,
            crescita degli stipendi, investimenti nell’ambito della transizione
            energetica e altri ancora. Tutto ciò avrà un impatto positivo sul
            mercato immobiliare, andando a creare nuove opportunità per gli
            investitori”.
          </p>
        </Container>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          I quattro trend 2024 nel mercato immobiliare
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Tra le principali tendenze del mercato immobiliare da tenere d’occhio
          nel 2024, ci sono le seguenti:
        </p>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            1. Reinvestire il capitale nei propri portafogli per riposizionarsi
            per il prossimo ciclo di mercato
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il 2024 sarà un anno di azione: rifinanziare, ricapitalizzare,
            ridurre le emissioni, rinnovare, riallocare il capitale e RI
            bilanciare il portafoglio. Tutte queste operazioni sugli Asset di un
            investitore potrebbero richiedere più di un anno, ma alla fine, si
            potrà ottenere un portafoglio ben posizionato per il prossimo ciclo
            di mercato.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            2. È buon momento per gli investimenti value add
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il repricing sulla qualità degli edifici dovrebbe creare un ambiente
            ideale per quegli investitori che vogliono acquistare e migliorare
            immobili o cambiarne la destinazione d’uso in qualcosa di più utile
            per la loro comunità locale. L’obiettivo di rendere il Real Estate
            più sostenibile potrebbe quindi trovare sostegno dal ritorno degli
            investitori disposti ad assumersi maggiori rischi in cambio di
            maggiori rendimenti, e potrebbe servire a frenare le costruzioni
            costose e ad alte emissioni.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            3. L’immobiliare può essere una soluzione per diversificare un
            portafoglio multi-Asset
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Di solito, gli investitori scelgono l’immobiliare per il rendimento,
            la tutela contro l’inflazione e per diversificare tra azioni e fixed
            income. Proprio la diversificazione è diventata particolarmente
            importante, dato che l’aumento dei tassi d’interessi ha portato alla
            crescita della correlazione tra azioni e obbligazioni. Il mercato
            europeo è stato anche capace di distribuire rendite e crescita del
            reddito in risposta all’inflazione; pertanto, il caso di
            investimento in questo settore nel 2024 è molto solido.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            4. Due categorie possono essere interessate da trend di investimento
            divergenti
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Quest’anno potremmo assistere ai grandi investitori che
            ricostruiscono un’esposizione verso certe tipologie di proprietà
            attraverso operazioni di portafoglio pan-europei e acquisizioni di
            società. Essi hanno l’esperienza necessaria per investire in
            segmenti di nicchia e in immobili con esposizione all'operatore
            sottostante. Nel frattempo, gli investitori nazionali più piccoli
            potrebbero sentirsi a proprio agio con la loro preferenza per
            l’abitativo, ma cercare allocazioni più personalizzate per
            tipologia. Altri ancora si aspettano che gli uffici costituiscano la
            loro principale esposizione, ma ci saranno anche quelli che
            potrebbero cercare un nuovo approccio.
          </p>
        </div>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article1Text;
