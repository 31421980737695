import React from "react";
import Card from "react-bootstrap/Card";
import article5Img from "../../asset/article5-img.png";

import { Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article5() {
  const navigate = useNavigate();

  const redirectArticle5 = () => {
    navigate("/article5");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article5Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            I virtual tour, la rivoluzione del mondo immobiliare.
          </Card.Title>

          <Button onClick={redirectArticle5} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article5;
