import React from "react";
import { Button } from "react-bootstrap";
import "../Style/buttonScrollForm.css";

const ScrollToContactButton = () => {
  const scrollToContact = () => {
    const contactForm = document.getElementById("SectionForm");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Button
      className="button-ScrollForm fw-bold fs-3"
      onClick={scrollToContact}
    >
      Fissa una call
    </Button>
  );
};

export default ScrollToContactButton;
