import React from "react";
import Card from "react-bootstrap/Card";
import article21Img from "../../asset/article21-img.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article21() {
  const navigate = useNavigate();

  const redirectArticle21 = () => {
    navigate("/article21");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article21Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Requisiti per il mutuo: <br /> come richiederlo per acquistare casa
          </Card.Title>

          <Button onClick={redirectArticle21} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article21;
