import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";

import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";

import backIntro from "../asset/1.png";
import ImgGeneralContractor from "../asset/general-contractor.png";
import IconAsta from "../asset/Asta.png";
import IconMercato from "../asset/mercatoLibero.png";
import ImgComplesso from "../asset/bg-complessoRistr.jpg";
import ImgSingolAbitation from "../asset/singleAbitation.png";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";

const ChiSiamoPage = () => {
  const chiSiamoStyle = {
    backgroundImage: `url(${backIntro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh", // Imposta l'altezza minima al 100% della viewport
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  const ComplessoCardStyle = {
    backgroundImage: `url(${ImgComplesso})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    width: "100%",
    backgroundPosition: "center",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const SingleAbitationCardStyle = {
    backgroundImage: `url(${ImgSingolAbitation})`, // Utilizza l'immagine di sfondo
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container
        fluid
        style={chiSiamoStyle}
        className="img-zoom overflowHidden"
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center  text-center fade-in h-100 "
        >
          <h1 className="mt-5 fw-bold red-logo  ">CHI SIAMO </h1>
        </div>
      </Container>
      <Container fluid className="p-5 color-blunav overflowHidden min-w100">
        <p className="text-center text-white fs-5 p-lg-5">
          11.22 Sviluppo Immobiliare Srl è una società innovativa di
          investimento attiva nel Real Estate a 360°, che propone operazioni
          profittevoli in maniera completamente passiva, specializzata
          prevalentemente in operazioni di compravendita nel settore
          residenziale e commerciale. <hr /> Ci occupiamo di operazioni di
          trading classico, frazionamenti, cambi di destinazione d’uso,
          costruzioni di fabbricati parziali o totali, allo scopo di recuperare
          valorizzare ed efficentare il patrimonio immobiliare italiano,
          garantendo i migliori standard qualitativi, e creando valore per i
          nostri investitori e partners.
        </p>
      </Container>
      <Container fluid className=" overflowHidden my-5">
        <h5 className="text-center fw-bold fs-2 p-4 red-logo mb-3">
          Il core business aziendale è quello di investire in Asset immobiliari
        </h5>
        <Row className="d.flex justify-content-center align-items-center">
          <Col
            data-aos="fade-up-right"
            data-aos-duration="1500"
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="text-center d-flex align-items-center justify-content-center  "
          >
            <p className=" width100Smarphone width70Pc  p-2 fs-5 line-height3 colortext-blunav">
              Operando come General Contractor, è possibile massimizzare le
              marginalità dei vari interventi di ristrutturazione o costruzione;
              gli immobili vengono riposizionati sul mercato a seguito di
              un’attenta riprogettazione e riqualificazione. <hr />
              <span className="fw-bold lineHeight2 fs-5">
                Offriamo agli investitori desiderosi di investire nel mercato
                immobiliare la possibilità di partecipare ad operazioni
                profittevoli e a basso rischio, assicurando il proprio denaro
                alla solidità del valore sottostante, rappresentato
                dall’immobile stesso.
              </span>
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex align-items-center justify-content-center "
            data-aos="fade-up-left"
            data-aos-duration="1500"
          >
            <img
              src={ImgGeneralContractor}
              className="width100Smarphone width70Pc  p-4"
              alt="img general contractor"
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="overflowHidden color-blunav shadow">
        <div className="d-flex align-items-center flex-column justify-content-center my-5 text-white">
          <h3 className="text-center fs-2 p-lg-5 ">
            Individuiamo Asset Immobiliari estremamente efficienti sotto il
            profilo del rapporto rischio rendimento <hr />{" "}
          </h3>
          <span className="fs-2">ed operiamo in 2 macro aree:</span>
        </div>
        <Row className="gap-5 d-flex align-items-center justify-content-center ">
          <Col
            className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5"
            lg={3}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <img className="w-70 p-3 " src={IconAsta} alt="" />
            <h4 className="text-center fw-bold color-title-card fs-2">
              Acquisto di immobili e terreni all’asta
            </h4>
          </Col>
          <Col
            className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5"
            lg={3}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <img className="w-70 p-3 " src={IconMercato} alt="" />
            <h4 className="text-center fw-bold color-title-card fs-2">
              Acquisto di immobili e terreni nel mercato libero
            </h4>
          </Col>
        </Row>
      </Container>
      <section className="bgBluOpacity05 shadow">
        <Container className="py-5 overflowHidden py-5 ">
          <Row className="d-flex justify-content-center align-items-center gap-5 ">
            <h3 className="text-center fs-1 fw-bold colortext-blunav">
              Le nostre operazioni si distinguono per le seguenti tipologie e
              caratteristiche:
            </h3>
            <Col
              style={ComplessoCardStyle}
              className=" text-center d-flex flex-column justify-content-center align-items-center mb-5 hover-card minHeightWidth colortext-blunav shadow"
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="flip-left"
              data-aos-duration="1500"
            >
              <h3 className="p-5 fw-bold fs-2">
                interventi su edifici composti da più unità abitative, che
                prevedono una riqualificazione a seguito di una ristrutturazione
                completa, o la costruzione dell’immobile stesso.
              </h3>
              <hr />
              <span className=" bold-900  fs-4 fst-italic ">
                Durata media dell’operazione tra i 12 e i 18 mesi
              </span>
            </Col>
            <Col
              className="text-center d-flex flex-column justify-content-center align-items-center mb-5 minHeightWidth hover-card colortext-blunav shadow"
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="flip-right"
              data-aos-duration="2000"
              data-aos-offset="300"
              style={SingleAbitationCardStyle}
            >
              <h3 className=" p-5 fw-bold fs-2 ">
                interventi su singole unità abitative, che prevedono una
                riqualificazione a seguito di una ristrutturazione parziale o di
                un restyling.
              </h3>
              <hr />
              <span className=" bold-900  fs-4 fst-italic colortext-blunav">
                Durata media dell’operazione tra i 6 e i 12 mesi.
              </span>
            </Col>
          </Row>
        </Container>
      </section>
      <CallToActionNumberGreen />

      <Footer />
    </>
  );
};
export default ChiSiamoPage;
