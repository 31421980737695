import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article10Img from "../../asset/article10-img.png";

const Article10Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article10Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Come si calcola il ROI e il ROE?
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center py-5">
          In questo articolo, ti accompagneremo nel mondo del ROI e del ROE.
          Scoprirai come questi strumenti possono essere determinanti nelle tue
          decisioni e come possono guidarti verso scelte vincenti nel campo
          degli investimenti immobiliari.
        </p>
      </Container>
      <Container fluid className="bg-white ">
        <Container className="py-5">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il <span className="bold-900 me-1">ROI (Return on Investment)</span>{" "}
            e il <span className="bold-900 me-1">ROE (Return on Equity)</span>{" "}
            sono due importanti indicatori finanziari che vengono utilizzati per
            valutare la redditività di un’azienda. Entrambi forniscono
            informazioni sul rendimento generato dagli investimenti effettuati
            dall’azienda, ma si concentrano su aspetti diversi.
          </p>
        </Container>
      </Container>

      <Container fluid className="py-5 bgBluOpacity05">
        <Container>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Il <span className="bold-900 me-1">ROI </span> viene calcolato
            dividendo il guadagno netto dell’azienda per l’investimento totale
            effettuato. Questo indicatore misura l’efficienza dell’azienda
            nell’utilizzo dei suoi capitali e consente di confrontare la
            redditività di diverse aziende o progetti. Un{" "}
            <span className="bold-900 me-1">ROI </span> più alto indica una
            maggiore efficienza nell’utilizzo del capitale investito.
          </p>
        </Container>
      </Container>
      <Container className="py-5">
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Il <span className="bold-900 me-1">ROE </span>, invece, si calcola
          dividendo l’utile di esercizio per il patrimonio netto della società.
          Questo indicatore misura la redditività generata dagli investitori e
          indica quanto profitto viene generato a partire da ogni euro investito
          dagli investitori. Un <span className="bold-900 me-1">ROE </span>
          più alto indica una maggiore redditività per gli investitori.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Entrambi gli indicatori sono importanti per gli investitori, in quanto
          forniscono informazioni sul rendimento degli investimenti effettuati
          nell’azienda. Tuttavia, è importante considerare anche altri fattori
          quando si valuta la redditività di un’azienda, come ad esempio il
          settore in cui opera, la concorrenza e le condizioni di mercato.
        </p>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article10Text;
