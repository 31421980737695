import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import logoPng from "../asset/logo-srl-png.png";
import "../Style/footerStyle.css";
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { BsFacebook } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GiPositionMarker } from "react-icons/gi";
import { BsFillTelephoneFill } from "react-icons/bs";
import numeroGreen from "../asset/numeroVerde1122SviluppoImmobiliare.png";
import { useNavigate } from "react-router-dom";
import logoIconAllCoding from "../asset/logoAllCoding(150x100px).png";

const Footer = () => {
  const navigate = useNavigate();

  const redirectInformationOnClick = () => {
    navigate("/information");
    window.scrollTo(0, 0);
  };

  const redirectJobClick = () => {
    navigate("/lavoraConNoi");
    window.scrollTo(0, 0);
  };

  const redirectChiSiamoOnClick = () => {
    navigate("/chiSiamo");
    window.scrollTo(0, 0);
  };
  const redirectinvestOnClick = () => {
    navigate("/investPage");
    window.scrollTo(0, 0);
  };

  const redirectFaqOnClick = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };

  const redirectMissionOnClick = () => {
    navigate("/laNostraMission");
    window.scrollTo(0, 0);
  };

  const redirectValoriOnClick = () => {
    navigate("/valori");
    window.scrollTo(0, 0);
  };

  const redirectContattiOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };

  return (
    <>
      {" "}
      <Container fluid className="pt-5 footer-background fw-bold ">
        <Row>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="d-flex  flex-column justify-content-center w-100 h-100 mb-5">
              <img className="logo-style " src={logoPng} alt="" />
            </div>
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="d-flex flex-column justify-content-start
             align-items-start mb-3 pt-5 "
          >
            <h5 className="mb-5 fs-3 text-center">Contatti</h5>
            <div className="d-flex align-items-center mb-3">
              <AiOutlineMail className="fs-4 me-2" />{" "}
              <span> info@1122sviluppoimmobiliare.com</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <AiOutlineMail className="fs-4 me-2" />{" "}
              <span>11.22sviluppoimmobiliare@pec.buffetti.it</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <GiPositionMarker className="fs-4 me-2" />{" "}
              <span> Piazzale G. delle Bande Nere 7, Milano 20146</span>
            </div>
            <div className="d-flex align-items-center mb-3 justify-content-center">
              <img
                className="w-numberGreen"
                src={numeroGreen}
                alt="numero verde "
              />
            </div>
          </Col>
          <Col
            lg={4}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center mt-5 "
          >
            <Button
              onClick={redirectChiSiamoOnClick}
              className="button-linkWhite fw-bold fs-5"
            >
              Chi siamo?
            </Button>
            <Button
              onClick={redirectinvestOnClick}
              className="button-linkWhite fw-bold fs-5"
            >
              Investi con noi
            </Button>
            <Button
              onClick={redirectMissionOnClick}
              className="button-linkWhite fw-bold fs-5"
            >
              La nostra mission
            </Button>
            <Button
              onClick={redirectValoriOnClick}
              className="button-linkWhite fw-bold fs-5"
            >
              I nostri valori
            </Button>
            <Button
              onClick={redirectJobClick}
              className="button-linkWhite fw-bold fs-5"
            >
              Lavora con noi
            </Button>
            <Button className="button-linkWhite fw-bold fs-5">
              Approfondimenti
            </Button>
            <Button
              onClick={redirectContattiOnClick}
              className="button-linkWhite fw-bold fs-5"
            >
              Contatti
            </Button>
            <Button
              onClick={redirectFaqOnClick}
              className="button-linkWhite fw-bold fs-5"
            >
              FAQ
            </Button>
          </Col>
        </Row>
        <div className="text-center  text-white marginSmarphone ">
          © Tutti i diritti sono riservati alla 11.22 Sviluppo Immobiliare srl{" "}
          <span className="mx-2">
            <a
              href="https://www.iubenda.com/privacy-policy/54067366"
              class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Privacy Policy "
            >
              Privacy Policy
            </a>
          </span>
          <span>
            <a
              href="https://www.iubenda.com/privacy-policy/54067366/cookie-policy"
              class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Privacy Policy "
            >
              Coockie Police
            </a>
          </span>
          <Button
            className="button-link fw-bold"
            onClick={redirectInformationOnClick}
          >
            {" "}
            Informativa sui rischi e sicurezza
          </Button>
        </div>
        <Container className="text-center py-3">
          <span>
            Sito web sviluppato dalla{" "}
            <a className="ms-1 colorAllCoding" href="https://www.allcoding.it/">
              All Coding
            </a>
            <img
              className="w-AllCodingLogo"
              src={logoIconAllCoding}
              alt="logo all coding"
            />
          </span>
        </Container>
      </Container>
    </>
  );
};

export default Footer;
