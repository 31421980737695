import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article5Img from "../../asset/article5-img.png";

const Article5Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article5Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          I virtual tour, la rivoluzione del mondo immobiliare.
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center">
          Le visite immobiliari virtuali stanno rivoluzionando il modo in cui le
          persone esplorano le proprietà in vendita. Grazie all’avanzamento
          della tecnologia e alla crescente domanda di soluzioni digitali, i
          virtual tour stanno diventando sempre più popolari nel settore
          immobiliare.
        </p>
      </Container>
      <Container fluid className="bg-white ">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Visitare casa da remoto{" "}
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Un tour virtuale immobiliare consente alle persone di esplorare una
            casa o un appartamento senza doversi recare sul posto. Utilizzando
            fotografie ad alta risoluzione o video interattivi, i visitatori
            possono “camminare” attraverso gli ambienti, esaminare i dettagli
            delle stanze e persino ottenere una visione a 360 gradi degli
            spazi. Ciò offre un’incredibile comodità sia per i potenziali
            acquirenti che per i venditori. I visitatori possono esplorare
            diverse proprietà da qualsiasi luogo e in qualsiasi momento, senza
            dover organizzare appuntamenti o spostare da una casa all’altra.
            Questo risparmia tempo ed energie sia per gli acquirenti che per gli
            agenti immobiliari.
          </p>
        </Container>
      </Container>

      <Container fluid className="py-5 bgBluOpacity05">
        <Container>
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Maggiore accuratezza dell’annuncio
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            I virtual tour immobiliari offrono una visione accurata delle
            proprietà. Le immagini ad alta definizione catturano ogni angolo e
            dettaglio, consentendo ai potenziali acquirenti di avere una
            panoramica completa degli spazi. Inoltre, alcuni tour virtuali
            consentono agli utenti di misurare le dimensioni delle stanze o di
            ottenere informazioni aggiuntive sui materiali utilizzati o sugli
            elettrodomestici presenti.
          </p>
        </Container>
      </Container>
      <Container>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          All’interno del video puoi farti un idea di come  Immobile Virtuale
           esplora gli interni di un alloggio di montagna completamente
          progettato in ogni sua finitura e dettaglio. Il massimo del realismo
          ottenuto completamente in computer grafica grazie a tecnologie
          all’avanguardia, per far emergere ogni dettaglio ed ogni finitura per
          il top dell’esperienza di navigazione.
        </p>{" "}
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          In generale i  virtual tour  possono essere particolarmente utile per
          gli acquirenti internazionali o per coloro che si trovano in un’altra
          città e desiderano valutare una proprietà senza dover viaggiare. Sono
          uno strumento efficace per mostrare le case durante periodi di
          restrizioni o emergenze sanitarie, quando le visite fisiche potrebbero
          essere limitate o non consentite.
        </p>{" "}
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Sono un’innovativa soluzione digitale che sta cambiando il modo in cui
          le persone cercano e visitano le proprietà in vendita. Offrono
          comodità, accuratezza e accessibilità, consentendo ai potenziali
          acquirenti di fare una scelta informata senza dover visitare ogni casa
          di interesse in quanto gli utenti possono muoversi attraverso gli
          spazi, guardarsi intorno e ottenere una visione a 360 gradi
          dell’ambiente. Hanno il controllo completo sull’esplorazione
          dell’immobile e possono spostarsi tra le stanze, ingrandire o ridurre,
          ruotare la vista e focalizzare i dettagli sui dettagli, consentendo
          loro di esaminare la struttura e i dettagli in modo dettagliato. Gli
          annunci immobiliari con tour virtuale tendono a ricevere più
          visualizzazioni online rispetto a quelle con solo immagini statiche in
          quanto catturano l’attenzione degli acquirenti potenziali, portando a
          un maggiore coinvolgimento e interazione con l’immobile, aumentando
          l’esperienza di visita ed una maggiore consapevolezza degli spazi.
        </p>{" "}
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article5Text;
