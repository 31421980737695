import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article2Img from "../../asset/article2-img.png";

const Article2Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article2Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Bonus edilizi 2024, scopri tutte le agevolazioni per la casa
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center">
          L’anno nuovo porta novità per chi effettua lavori nella propria
          abitazione? Che si tratti di lavori su unifamiliari o interventi in
          condominio, sono diverse le modifiche per i bonus edilizi 2024,
          partendo dal superbonus e passando per la cessione del credito, ma non
          solo. <hr /> Scopriamo tutto quello che c’è da sapere sui bonus casa
          2024 per sfruttarli al meglio, passando sotto la lente di
          ingrandimento le agevolazioni confermate per il nuovo anno, quelle che
          vengono modificate e anche quelle che non saranno più utilizzabili.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Superbonus 2024
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            La formula del superbonus 2024 prevede che la detrazione fruibile
            passa dal 90% del 2023 al 70%. La legge di Bilancio 2024, inoltre,
            prevede che venga  tassata al 26% la plusvalenza  ottenuta
            rivendendo una casa che è stata ristrutturata con il superbonus 110.
            Va segnalato anche un decreto approvato in extremis in Consiglio dei
            Ministri del 28 Dicembre 2023 tramite cui viene  introdotta una
            sanatoria per i cantieri già avviati nel corso del 2023 , ma solo
            per chi rispetta determinati requisiti. È stato anche approvato un
            contributo straordinario per i redditi bassi.
          </p>
        </Container>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Ecobonus 2024
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Fa parte del pacchetto di bonus casa 2024 anche l’ecobonus,
          una detrazione Irpef da sfruttare in 10 anni in sede di dichiarazione
          dei redditi, può variare tra il 50% e il 75% in funzione della
          tipologia di lavori realizzati. Dal 17 Febbraio 2023, data di entrata
          in vigore del “decreto Cessioni”, per gli interventi di efficienza
          energetica previsti dall’articolo 14 del Dl 63/2013, non è più
          possibile optare per lo “sconto in fattura” o per la cessione del
          credito d’imposta corrispondente alla detrazione spettante.
        </p>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Bonus ristrutturazione
          </h2>
          <p className=" colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il  bonus ristrutturazione  è stato prorogato fino 2024 dal governo
            Meloni con la legge di Bilancio. Si tratta di una detrazione Irpef
            del 50% per i lavori di ristrutturazione. La detrazione si può
            effettuare in 10 anni e in quote di pari importo sul 50% delle spese
            sostenute per interventi di manutenzione ordinaria e straordinaria.
            Il tetto di spesa è di 96.000,00 euro per unità immobiliare.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Come funziona il bonus infissi 2024?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            In realtà non esiste un bonus infissi vero e proprio. Tuttavia, è
            possibile lo stesso ottenere delle agevolazioni per la sostituzione
            degli infissi. Per farlo, si può ricorrere, principalmente, a due
            bonus edilizi: l’ecobonus e il bonus ristrutturazione, entrambi
            nella misura del 50%.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Sismabonus
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il sismabonus, prevede che, per le spese sostenute dal primo Gennaio
            2017 al 31 Dicembre 2024, venga riconosciuta
            una detrazione del 50% da calcolare su un ammontare massimo di
            96.000,00 euro per unità immobiliare (per ciascun anno), da
            ripartire in 5 quote annuali di pari importo. La detrazione al 70 o
            all'80% riguarda gli interventi che riducono il rischio sismico di 1
            o 2 classi e quando i lavori sono stati realizzati sulle parti
            comuni di edifici condominiali sarà pari all'80
            o all'85% (supersismabonus).
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Bonus verde
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il  bonus verde , invece, è un’agevolazione pensata per la cura
            di giardini, terrazzi e aree scoperte, che siano di pertinenza di
            immobili a uso abitativo. Nel dettaglio, si traduce in uno sconto
            del 36% entro un limite di spesa di 5.000,00 euro (suddivisa in
            dieci quote annuali di pari importo). Tuttavia, Le spese che non
            rientrano nel bonus verde 2024 sono:
          </p>
        </div>
        <div className="align-items-start">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              Manutenzione ordinaria: di giardini già esistenti e con regolarità
              periodica, non sostenendo alcuna innovazione;
            </li>
            <li>
              Acquisto di attrezzature specifiche: pale, picconi e tagliaerba;
            </li>
            <li>Interventi lavorativi da parte del proprietario.</li>
          </ul>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Bonus mobili 2024
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Nel 2024 si rinnova la formula del  bonus mobili  (o bonus
            elettrodomestici 2024), cambiando parametri rispetto agli anni
            precedenti. Dal 1° Gennaio 2024 saranno agevolabili le spese entro
            il limite massimo di 5.000,00 euro per l’acquisto di mobili e di
            grandi elettrodomestici per l’arredo di un immobile oggetto di
            interventi di ristrutturazione e recupero del patrimonio edilizio.
            Lo scorso anno, invece, l’importo massimo detraibile era di 8.000,00
            euro.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Bonus barriere architettoniche 2024
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il  bonus barriere architettoniche 2024  può essere applicato solo
            per la realizzazione di interventi che riguardano scale, rampe,
            ascensori, servoscala e piattaforme elevatrici e limitatamente a
            edifici già esistenti. L'agevolazione al 75%, di fatto, consiste
            nell'eliminazione di ostacoli verticali.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Come funziona il bonus facciate nel 2024?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Nessuna novità per il bonus facciate. Come è noto, la manovra di
            Bilancio 2021 aveva ridotto l'agevolazione dal 90% al 60%, prima
            della proroga fino al 31 Dicembre 2022. Tuttavia, il bonus facciate
            era già andato in pensione nel 2023 e non sarà possibile scaricare
            le spese per i lavori eseguiti sulle facciate nemmeno nel 2024.
          </p>
        </div>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article2Text;
