import React from "react";

import Card from "react-bootstrap/Card";
import article2Img from "../../asset/article2-img.png";

import { Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article2() {
  const navigate = useNavigate();

  const redirectArticle2 = () => {
    navigate("/article2");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article2Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Bonus edilizi 2024, scopri tutte le agevolazioni per la casa
          </Card.Title>

          <Button onClick={redirectArticle2} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article2;
