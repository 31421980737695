import React from "react";
import Card from "react-bootstrap/Card";
import article17Img from "../../asset/article17-img.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article17() {
  const navigate = useNavigate();

  const redirectArticle17 = () => {
    navigate("/article17");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article17Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Tutti i passaggi per acquistare casa: dal preliminare al rogito
          </Card.Title>

          <Button onClick={redirectArticle17} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article17;
