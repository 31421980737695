import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import ContactFormSmall from "../Components/ContactFormSmall";
import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";

import backMissionIntro from "../asset/missionBG-80op.png";
import trustImg from "../asset/fiduciaCliente.png";
import SecurityInvestimentImg from "../asset/sicurityInvestiment.png";
import City from "../asset/city.png";

import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import bgClassEnergy from "../asset/caseGreen5.jpg";
import ContactForm from "../Components/ContactForm";
import { Helmet } from "react-helmet";

const LaNostraMission = () => {
  const MissionIntroPageStyle = {
    backgroundImage: `url(${backMissionIntro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>La missione della 1122 sviluppo immobiliare</title>
        <meta
          name="description"
          content="La Missione della società 1122 Sviluppo Immobiliare Srl è quella di rendere accessibile il settore degli investimenti immobiliari atutti coloro che non dispongono di grandi capitali, così da poterottenere delle rendite profittevoli, in maniera completamente passiva."
        />
      </Helmet>

      <BarraLaterale />
      <Container
        fluid
        style={MissionIntroPageStyle}
        className="img-zoom overflowHidden"
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center  text-center fade-in h-100 "
        >
          <h1 className="mt-5 fw-bold red-logo ">LA NOSTRA MISSION </h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav overflowHidden ">
        <p className="  fs-3 text-white  text-center p-lg-5 ">
          <span className=" fw-bold fs-3 d-block">
            La Mission della società 11.22 Sviluppo Immobiliare Srl è quella di
            rendere accessibile il settore degli investimenti immobiliari a
            tutti coloro che non dispongono di grandi capitali, così da poter
            offrire l’opportunità di entrare in questo settore ed ottenere delle
            rendite profittevoli, in maniera completamente passiva.
          </span>{" "}
          <hr />
          Rivolgendosi a noi, ci si affida ad una società che agisce nel
          rispetto di principi morali e valori condivisi dalla comunità, con un
          alto grado di professionalità e di affidabilità.
        </p>
      </Container>
      <Container fluid className="overflowHidden bgBluOpacity05  shadow py-4">
        <Container>
          <Row className=" d-flex align-items-center justify-content-start ">
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h4 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  Fiducia
                </h4>
                <p className="colortext-blunav fw-bold fs-5 w-100 .line-height3">
                  Il rapporto che instauriamo con i nostri investitori è basato
                  sulla fiducia, ripagata seguendo e curando l’investimento
                  immobiliare in ogni suo dettaglio, affinché possa offrire il
                  massimo profitto.
                  <hr />
                  Siamo fortemente ancorati ai valori tradizionali del concetto
                  di famiglia formata da collaboratori che tutti i giorni si
                  mettono in gioco agendo con etica, professionalità,
                  determinazione, responsabilità e disciplina assicurando a chi
                  investe con noi il rispetto degli accordi presi e della parola
                  data.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <img
                src={trustImg}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <img
                src={City}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h4 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  Innovazione
                </h4>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  La nostra Mission deriva da una grande passione, che ci ha
                  portato a creare un sistema innovativo che integra la realtà
                  degli investimenti con quella immobiliare.
                </p>
              </div>
            </Col>

            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="300"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h4 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  Sicurezza
                </h4>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Il nostro focus è quello di concludere profittevolmente ogni
                  singolo investimento immobiliare, ottimizzando la marginalità,
                  con grande soddisfazione per tutte le parti coinvolte.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-offset="300"
              data-aos-duration="1500"
            >
              <img
                src={SecurityInvestimentImg}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      {/*  <Container fluid style={ClassEnergyStyle}>
        <Row className="d-flex ">
          <Col
            data-aos="zoom-in-left"
            data-aos-offset="300"
            data-aos-duration="3000"
            className=" hover-img  rounded flex-column d-flex justify-content-center  align-items-center  py-2"
          >
            <p className=" text-center fw-bold  rounded mb-3  fs-2 width-100Smarphone width-70Pc  fw-bold  line-height3 colortext-blunav py-5">
              Sosteniamo un approccio olistico alla crescita e allo sviluppo,
              che tiene conto dell'impatto sociale, ambientale ed economico
              delle nostre azioni. <br /> Riteniamo che la riqualificazione
              energetica residenziale sia un tema cruciale nell’attuale contesto
              ambientale ed economico. <br /> Ci impegniamo a riqualificare
              energeticamente gli immobili sui quali interveniamo, migliorando
              la classe energetica di appartenenza mirando quindi alla riduzione
              del fabbisogno energetico con un conseguente abbattimento delle
              emissioni nell’ambiente.
            </p>
          </Col>
        </Row>
      </Container> */}
      <Container className="my-5">
        <h2 className="text-center fw-bold red-logo mb-5 fs-1">
          Riteniamo che la riqualificazione energetica residenziale sia un tema
          cruciale nell’attuale contesto ambientale ed economico.
        </h2>
        <Row>
          <Col
            className="text-white d-flex flex-column justify-content-center align-items-center "
            lg={6}
            md={12}
            sm={12}
            xs={12}
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="200"
          >
            <p className="mt-3 line-height3 fs-5 text-center colortext-blunav fw-bold p-2">
              Sosteniamo un approccio olistico alla crescita e allo sviluppo,
              che tiene conto dell'impatto sociale, ambientale ed economico
              delle nostre azioni. <hr /> Ci impegniamo a riqualificare
              energeticamente gli immobili sui quali interveniamo, migliorando
              la classe energetica di appartenenza mirando quindi alla riduzione
              del fabbisogno energetico con un conseguente abbattimento delle
              emissioni nell’ambiente.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-offset="200"
          >
            {" "}
            <img
              src={bgClassEnergy}
              alt="img presentazione "
              className="rounded w-70 mb-4 shadow "
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="overflowHidden py-5 shadow">
        <Container
          className="overflowHidden  "
          data-aos="fade-left"
          data-aos-offset="300"
          data-aos-duration="1500"
        >
          <h2 className="text-center fw-bold pt-5 mb-5 fs-1 .colortext-blunav red-logo">
            Proteggere il capitale
          </h2>
          <p className="text-center fw-bold  mb-5 fs-3 colortext-blunav ">
            Crediamo che gli investimenti immobiliari possano aiutare a
            migliorare la vita economica delle persone, delle famiglie e delle
            generazioni future, per questo il nostro approccio è focalizzato a
            proteggere il capitale investito.
          </p>
        </Container>
      </Container>

      <CallToActionNumberGreen />
      <ContactFormSmall />
      <Footer />
    </>
  );
};
export default LaNostraMission;

/* <Container
        fluid
        className=" py-5 color-blunav overflowHiddenSecondaryPageIntro"
      >
        <h1 className=" text-center text-white fw-bold">
          Investi in sicurezza nei nostri progetti nel Real Estate. <hr />
          Investimenti mirati a creare valore.
        </h1>
        <p className=" p-4 fs-5 text-white  text-center">
          <span className=" fw-bold fs-3 d-block">
            Scegli il mercato Immobiliare per il suo rapporto esclusivo rischio
            rendimento.
          </span>{" "}
          Se vuoi trasformare un investimento improvvisato in un investimento
          conveniente, profittevole e professionale; devi arginare i rischi e
          agire con metodo e strategia per massimizzare la redditività.
        </p>
      </Container> */
