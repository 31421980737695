import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";

import article13Img from "../../asset/article13-img.png";

const Article13Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/approfondimenti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article13Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Rogito notarile: quali sono le conseguenze se non si rispettano i
          termini?
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          L’acquisto di una casa è spesso uno degli investimenti più
          significativi nella vita di una persona. In Italia, come in molte
          altre parti del mondo, il compromesso immobiliare svolge un ruolo
          fondamentale in questo processo, rappresentando una tappa cruciale che
          segna l’impegno serio tra acquirente e venditore. In questo articolo,
          esploreremo il significato del compromesso immobiliare, la sua
          importanza e gli elementi chiave che lo caratterizzano oltre alle
          scadenze in termini temporali per il rogito.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Cos’è il compromesso?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il compromesso immobiliare è un contratto preliminare che sancisce
            l’accordo tra acquirente e venditore per la compravendita di un
            immobile. Questo documento, spesso redatto con l’assistenza di un
            notaio o di un professionista del settore, contiene i dettagli
            essenziali dell’affare, delineando i termini e le condizioni che
            regolamentano la transazione.
          </p>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Cosa succede in caso di mancato rispetto del termine per il rogito
              notarile?
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              All’interno del compromesso è presente una clausola denominata
              ‘’Termine per il rogito notarile’’. Questa clausola indica il
              termine entro il quale il contratto definitivo di compravendita
              (rogito notarile) deve essere stipulato. Solitamente, si tratta di
              un periodo compreso tra 30 e 180 giorni dalla firma del
              preliminare. Se una delle parti non adempie al compromesso,
              l’altra può agire in tribunale per risarcimento o esecuzione
              forzata. Questo può portare alla risoluzione del  contratto  e al
              trasferimento coattivo della proprietà. Il termine di prescrizione
              per far valere i diritti è di 10 anni. In caso di inadempimento, è
              possibile trattenere la caparra o agire per ottenere il
              risarcimento del danno. La concessione dell’uso della casa può
              complicare la situazione, richiedendo intervento giudiziale per
              recuperare il possesso e stabilire un risarcimento per eventuali
              ritardi.
            </p>
          </div>
        </Container>
      </Container>

      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article13Text;
