import React from "react";
import NavigationBar from "../Components/NavigationBar";
import Intro from "../Components/Intro";
import ChiSiamo from "../Components/ChiSiamo";
import BarraLaterale from "../Components/BarraLaterale";

import Footer from "../Components/Footer";
import Mission from "../Components/Mission";
import ContactFormSmall from "../Components/ContactFormSmall";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import { Helmet } from "react-helmet";

const Homepage = () => {
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>1122 sviluppo immobiliare</title>
        <meta
          name="description"
          content="la 1122 sviluppo immobiliare e una società di investimenti immobiliare che punta a far generare ai propri investitori una rendita passiva annua"
        />
      </Helmet>

      <BarraLaterale />
      <Intro />
      <ChiSiamo />
      <Mission />
      <CallToActionNumberGreen />
      <ContactFormSmall />

      <Footer />
    </>
  );
};

export default Homepage;
